import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navigation from "./navigation.js";
import Footer from "./footer.js";
import HeroImage from "./heroimage.js";
import ServicesPageContent from "./servicesPageContent.js";

class Services extends Component {
  render() {
    return (
      <div>
        <Navigation />
        <div className="spacer"></div>
        <HeroImage content="SERVICES" />
        <ServicesPageContent />
        <Footer />
      </div>
    );
  }
}
export default Services;
