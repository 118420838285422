import React, { Component } from "react";
import { Link } from "react-router-dom";
import Gallery from "react-photo-gallery";
const image1 = require("../images/1.jpg");
const image2 = require("../images/2.jpg");
const image3 = require("../images/3.jpg");
const image4 = require("../images/4.jpg");
const image5 = require("../images/5.jpg");
const image6 = require("../images/6.jpg");
const image7 = require("../images/7.jpg");
const image8 = require("../images/8.jpg");
const image9 = require("../images/9.jpg");
const image10 = require("../images/10.jpg");
const image11 = require("../images/11.jpg");
const image12 = require("../images/12.jpg");
const image13 = require("../images/13.jpg");
const image14 = require("../images/14.jpg");
const image15 = require("../images/15.jpg");
const image16 = require("../images/16.jpg");
const image17 = require("../images/17.jpg");
const image18 = require("../images/18.jpg");
const image19 = require("../images/19.jpg");
const image20 = require("../images/20.jpg");
const image21 = require("../images/21.jpg");
const image22 = require("../images/22.jpg");
const image23 = require("../images/23.jpg");
const image24 = require("../images/24.jpg");
const image25 = require("../images/25.jpg");
const image26 = require("../images/26.jpg");
const image27 = require("../images/27.jpg");
const image28 = require("../images/28.jpg");
const image29 = require("../images/29.jpg");
const image30 = require("../images/30.jpg");
const image31 = require("../images/31.jpg");
const image32 = require("../images/32.jpg");
const image33 = require("../images/33.jpg");
const image34 = require("../images/34.jpg");
const image35 = require("../images/35.jpg");

class Services extends Component {
  render() {
    const photos = [
      {
        src: image1,
        width: 4,
        height: 3,
      },
      {
        src: image2,
        width: 1,
        height: 1,
      },
      {
        src: image3,
        width: 1,
        height: 1,
      },
      {
        src: image4,
        width: 1,
        height: 1,
      },
      {
        src: image5,
        width: 1,
        height: 1,
      },
      {
        src: image6,
        width: 1,
        height: 1,
      },
      {
        src: image7,
        width: 1,
        height: 1,
      },
      {
        src: image8,
        width: 1,
        height: 1,
      },
      {
        src: image9,
        width: 1,
        height: 1,
      },
      {
        src: image10,
        width: 1,
        height: 1,
      },
      {
        src: image11,
        width: 1,
        height: 1,
      },
      {
        src: image12,
        width: 1,
        height: 1,
      },
      {
        src: image13,
        width: 1,
        height: 1,
      },
      {
        src: image14,
        width: 1,
        height: 1,
      },
      {
        src: image15,
        width: 1,
        height: 1,
      },
      {
        src: image16,
        width: 1,
        height: 1,
      },
      {
        src: image17,
        width: 1,
        height: 1,
      },
      {
        src: image18,
        width: 1,
        height: 1,
      },
      {
        src: image19,
        width: 1,
        height: 1,
      },
      {
        src: image20,
        width: 1,
        height: 1,
      },
      {
        src: image21,
        width: 1,
        height: 1,
      },
      {
        src: image22,
        width: 1,
        height: 1,
      },
      {
        src: image23,
        width: 1,
        height: 1,
      },
      {
        src: image24,
        width: 1,
        height: 1,
      },
      {
        src: image25,
        width: 1,
        height: 1,
      },
      {
        src: image26,
        width: 1,
        height: 1,
      },
      {
        src: image27,
        width: 1,
        height: 1,
      },
      {
        src: image28,
        width: 1,
        height: 1,
      },
      {
        src: image29,
        width: 1,
        height: 1,
      },
      {
        src: image30,
        width: 1,
        height: 1,
      },
      {
        src: image31,
        width: 1,
        height: 1,
      },
      {
        src: image32,
        width: 1,
        height: 1,
      },
      {
        src: image33,
        width: 1,
        height: 1,
      },
      {
        src: image34,
        width: 1,
        height: 1,
      },
      {
        src: image35,
        width: 1,
        height: 1,
      },
    ];

    return (
      <div className="gallery">
        <p>
          Please see some exaples of the work we do. If you would like to get a
          quote some somehting you see, or would like more info about a specific
          project please get in-touch!
        </p>
        <Gallery photos={photos} />
      </div>
    );
  }
}
export default Services;
