import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navigation from "./navigation.js";
import Footer from "./footer.js";
import HeroImage from "./heroimage.js";
import GalleryContainer from "./galleryContainer.js";
import SampleGallery from './sampleGallery.js';

const image1 = require("../samples/miscellaneous/Decking 2.jpg");
const image2 = require("../samples/miscellaneous/Audio unit 2.jpg");
const image3 = require("../samples/miscellaneous/audio unit 3.jpg");
const image4 = require("../samples/miscellaneous/Audion unit 1.jpg");
const image5 = require("../samples/miscellaneous/AWO veneer Coffeee Storage table.jpg");
const image6 = require("../samples/miscellaneous/Bathroom door.jpg");
const image7 = require("../samples/miscellaneous/Decking 3.jpg");
const image8 = require("../samples/miscellaneous/exterior door 2.jpg");
const image9 = require("../samples/miscellaneous/Exterior door.jpg");
const image10 = require("../samples/miscellaneous/Fence 2.jpg");
const image11 = require("../samples/miscellaneous/Fence 3.jpg");
const image12 = require("../samples/miscellaneous/Fence.jpg");
const image13 = require("../samples/miscellaneous/Fire door.jpg");
const image14 = require("../samples/miscellaneous/Flood block 2.jpg");
const image15 = require("../samples/miscellaneous/flood block.jpg");
const image16 = require("../samples/miscellaneous/French doors   (4).jpg");
const image17 = require("../samples/miscellaneous/handrail 2.jpg");
const image18 = require("../samples/miscellaneous/handrail 3.jpg");
const image19 = require("../samples/miscellaneous/handrail.jpg");
const image20 = require("../samples/miscellaneous/Internal French doors (2).jpg");
const image21 = require("../samples/miscellaneous/Internal French doors.jpg");
const image22 = require("../samples/miscellaneous/Jackie Bell Vanity (6).JPG");
const image23 = require("../samples/miscellaneous/Jackie Bell Vanity (10).JPG");
const image24 = require("../samples/miscellaneous/Kids playhouse 2.jpg");
const image25 = require("../samples/miscellaneous/Kids playhouse.jpg");
const image26 = require("../samples/miscellaneous/Mirror Plinth.jpg");
const image27 = require("../samples/miscellaneous/New Front Door  (3).jpg");
const image28 = require("../samples/miscellaneous/New Front Door & Posts (3).jpg");
const image29 = require("../samples/miscellaneous/Nicola Caisley (5).jpg");
const image30 = require("../samples/miscellaneous/Post box 2.jpg");
const image31 = require("../samples/miscellaneous/Post box 3.jpg");
const image32 = require("../samples/miscellaneous/Post box.jpg");
const image33= require("../samples/miscellaneous/radiator box 1.jpg");
const image34 = require("../samples/miscellaneous/radiator box.jpg");
const image35 = require("../samples/miscellaneous/Rebak Doors (7).JPG");
const image36 = require("../samples/miscellaneous/Reinforced table.jpg");
const image37 = require("../samples/miscellaneous/Stairs 2.jpg");
const image38 = require("../samples/miscellaneous/Stairs.jpg");
const image39 = require("../samples/miscellaneous/Teviot House Cherry Post unit (2).jpg");
const image40 = require("../samples/miscellaneous/Window frame.jpg");

let images =
[{
        src: image1,
        thumbnail: image1,
        thumbnailWidth: 300,
        thumbnailHeight: 320,
},{
    src: image2,
    thumbnail: image2,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image3,
    thumbnail: image3,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image4,
    thumbnail: image4,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image5,
    thumbnail: image5,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image6,
    thumbnail: image6,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image7,
    thumbnail: image7,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image8,
    thumbnail: image8,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image9,
    thumbnail: image9,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image10,
    thumbnail: image10,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image11,
    thumbnail: image11,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image12,
    thumbnail: image12,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image13,
    thumbnail: image13,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image14,
    thumbnail: image14,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image15,
    thumbnail: image15,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image16,
    thumbnail: image16,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image17,
    thumbnail: image17,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image18,
    thumbnail: image18,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image19,
    thumbnail: image19,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image20,
    thumbnail: image20,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image21,
    thumbnail: image21,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image22,
    thumbnail: image22,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image23,
    thumbnail: image23,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image24,
    thumbnail: image24,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image25,
    thumbnail: image25,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image26,
    thumbnail: image26,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image27,
    thumbnail: image27,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image28,
    thumbnail: image28,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image29,
    thumbnail: image29,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image30,
    thumbnail: image30,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image31,
    thumbnail: image31,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image32,
    thumbnail: image32,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image33,
    thumbnail: image33,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image34,
    thumbnail: image34,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image35,
    thumbnail: image35,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image36,
    thumbnail: image36,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image37,
    thumbnail: image37,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image38,
    thumbnail: image38,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image39,
    thumbnail: image39,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image40,
    thumbnail: image40,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
}
]


class Miscellaneous extends Component {
  render() {
    return (
      <div>
        <Navigation />
        <div className="spacer"></div>
        <HeroImage content="OTHER" />
        <Link to="/samples">
        <div className='backtosamples'>&larr; Back to samples</div>
        </Link>
        <div className="sampleImageContainer">
            <SampleGallery images={images}></SampleGallery>
        </div>   
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <Footer />
      </div>
    );
  }
}
export default Miscellaneous;
