import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navigation from "./navigation.js";
import Footer from "./footer.js";
import HeroImage from "./heroimage.js";
import HomepageContent from "./homepage-content.js";

class Home extends Component {
  render() {
    return (
      <div>
        <Navigation />
        <div className="spacer"></div>
        <div className="logo"></div>
        <HeroImage content="CREATIVE CARPENTRY & JOINERY" />
        <HomepageContent />        
        <Footer />
      </div>
    );
  }
}
export default Home;
