import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navigation from "./navigation.js";
import Footer from "./footer.js";
import HeroImage from "./heroimage.js";
import GalleryContainer from "./galleryContainer.js";
import SampleGallery from './sampleGallery.js';


const image1 = require("../samples/kitchens/Aldeburgh 2004 Kitchen (6).jpg");
const image2 = require("../samples/kitchens/Aldsworth Dresser (5).JPG");
const image3 = require("../samples/kitchens/Aldsworth Kitchen (4).JPG");
const image4 = require("../samples/kitchens/Aldsworth Kitchen (5).JPG");
const image5 = require("../samples/kitchens/Aldsworth Kitchen (9).JPG");
const image6 = require("../samples/kitchens/Crossley Kitchen Fridge Unit (2).jpg");
const image7 = require("../samples/kitchens/Crossley Kitchen Fridge Unit (3).jpg");
const image8 = require("../samples/kitchens/Crossley Kitchen Fridge Unit (4).jpg");
const image9 = require("../samples/kitchens/Crossley Kitchen Fridge Unit (5).jpg");
const image10 = require("../samples/kitchens/Daisy & Gareth Kitchen (10).jpg");
const image11 = require("../samples/kitchens/Daisy & Gareth Kitchen (12).jpg");
const image12 = require("../samples/kitchens/Daisy & Gareth Kitchen (18).jpg");
const image13 = require("../samples/kitchens/Daisy & Gareth Kitchen (19).jpg");
const image14 = require("../samples/kitchens/Dolores Kitchen Bookcases (2).jpg");
const image15 = require("../samples/kitchens/Dolores Kitchen Bookcases (3).jpg");
const image16 = require("../samples/kitchens/Dolores Kitchen Bookcases (4).jpg");
const image17 = require("../samples/kitchens/Dolores Kitchen Bookcases (5).jpg");
const image18 = require("../samples/kitchens/Dolores Kitchen Bookcases.jpg");
const image19 = require("../samples/kitchens/Kent Kitchen  (3) - Copy.JPG");
const image20 = require("../samples/kitchens/Kent Kitchen  (5).JPG");
const image21 = require("../samples/kitchens/Kent Kitchen  (6) - Copy.JPG");
const image22 = require("../samples/kitchens/Kent Kitchen  (7) - Copy.JPG");
const image23 = require("../samples/kitchens/Kent Kitchen  (9).JPG");
const image24 = require("../samples/kitchens/Kent Kitchen .JPG");
const image25 = require("../samples/kitchens/Lexham Gardens Kitchen (2).jpg");
const image26 = require("../samples/kitchens/Lexham Gardens Kitchen (5).jpg");
const image27 = require("../samples/kitchens/Lexham Gardens Kitchen (8).jpg");
const image28 = require("../samples/kitchens/Lexham Gardens Kitchen (9).jpg");
const image29 = require("../samples/kitchens/Olivia Kitchen (2).jpg");
const image30 = require("../samples/kitchens/Olivia Kitchen (4).jpg");
const image31 = require("../samples/kitchens/Olivia Kitchen (7).jpg");
const image32 = require("../samples/kitchens/Olivia Kitchen (9).jpg");
const image33 = require("../samples/kitchens/Olivia Kitchen.jpg");
const image34 = require("../samples/kitchens/Russell Road Kitchen (2).jpg");
const image35 = require("../samples/kitchens/Russell Road Kitchen (3).jpg");
const image36 = require("../samples/kitchens/Russell Road Kitchen (5).jpg");
const image37 = require("../samples/kitchens/Southgrove Kitchen Cooker Base.JPG");
const image38 = require("../samples/kitchens/Southgrove Kitchen Cooker Wall.JPG");
const image39 = require("../samples/kitchens/Southgrove Kitchen Draw.JPG");
const image40 = require("../samples/kitchens/Southgrove Kitchen sink-Dishwasher.JPG");
const image41 = require("../samples/kitchens/Southgrove Kitchen Sink.JPG");
const image42 = require("../samples/kitchens/Southgrove Kitchen wall glazed.JPG");
const image43 = require("../samples/kitchens/Southgrove utility sink 4.JPG");
const image44 = require("../samples/kitchens/Southgrove Wall units.JPG");

let images =
[{
        src: image1,
        thumbnail: image1,
        thumbnailWidth: 300,
        thumbnailHeight: 320,
},{
    src: image2,
    thumbnail: image2,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image3,
    thumbnail: image3,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image4,
    thumbnail: image4,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image5,
    thumbnail: image5,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image6,
    thumbnail: image6,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image7,
    thumbnail: image7,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image8,
    thumbnail: image8,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image9,
    thumbnail: image9,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image10,
    thumbnail: image10,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image11,
    thumbnail: image11,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image12,
    thumbnail: image12,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image13,
    thumbnail: image13,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image14,
    thumbnail: image14,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image15,
    thumbnail: image15,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image16,
    thumbnail: image16,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image17,
    thumbnail: image17,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image18,
    thumbnail: image18,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image19,
    thumbnail: image19,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image20,
    thumbnail: image20,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image21,
    thumbnail: image21,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image22,
    thumbnail: image22,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image23,
    thumbnail: image23,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image24,
    thumbnail: image24,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image25,
    thumbnail: image25,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image26,
    thumbnail: image26,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image27,
    thumbnail: image27,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image28,
    thumbnail: image28,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image29,
    thumbnail: image29,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image30,
    thumbnail: image30,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image31,
    thumbnail: image31,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image32,
    thumbnail: image32,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image33,
    thumbnail: image33,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image34,
    thumbnail: image34,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image35,
    thumbnail: image35,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image36,
    thumbnail: image36,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image37,
    thumbnail: image37,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image38,
    thumbnail: image38,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image39,
    thumbnail: image39,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image40,
    thumbnail: image40,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image41,
    thumbnail: image41,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image42,
    thumbnail: image42,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image43,
    thumbnail: image43,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image44,
    thumbnail: image44,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
}
]


class Kitchens extends Component {
  render() {
    return (
      <div>
        <Navigation />
        <div className="spacer"></div>
        <HeroImage content="KITCHENS" />
        <Link to="/samples">
        <div className='backtosamples'>&larr; Back to samples</div>
        </Link>
        <div className="sampleImageContainer">
            <SampleGallery images={images}></SampleGallery>
        </div>   
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <Footer />
      </div>
    );
  }
}
export default Kitchens;
