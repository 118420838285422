import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navigation from "./navigation.js";
import Footer from "./footer.js";
import HeroImage from "./heroimage.js";
import GalleryContainer from "./galleryContainer.js";
import SampleGallery from './sampleGallery.js';

class Services extends Component {
  render() {
    return (
      <div>
        <Navigation />
        <div className="spacer"></div>
        <HeroImage content="SAMPLES" />
        <div className="sampleImageTitleContainer">
          
        <Link to="/samples/bedroom-furniture">
        <div className="sampleTitle">Bedroom Furniture</div>
        </Link>
          
        <Link to="/samples/kitchens">
          <div className="sampleTitle">Kitchens</div>
        </Link>

        <Link to="/samples/wardrobes">
          <div className="sampleTitle">Wardrobes</div>
        </Link>
        <Link to="/samples/storage">
          <div className="sampleTitle">Storage Solutions</div>
        </Link>
        <Link to="/samples/miscellaneous">
          <div className="sampleTitle">Other</div>
        </Link>
        </div>
       
       
       
        {/* <SampleGallery></SampleGallery> */}
        <Footer />
      </div>
    );
  }
}
export default Services;
