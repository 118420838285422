import React, { Component } from "react";
import { Link } from "react-router-dom";

class ServicesPageContent extends Component {
  render() {
    return (
      <div className="homepage-content">
        <div>
          I offer a range of services to help get you what you want, take a look
          at the list below to see what I specialise in.
        </div>
        <div className="sub-copy">
          <ul>
            <p>Purpose made wardrobes, bookcases, audio and alcove units.</p>
            <p>
              All types of carpentry, locks and security, wooden and laminated
              floors.
            </p>
            <p>Kitchens supplied and fitted.</p>{" "}
            <p>
              New staircases, refurbishment of existing - newels, handrails and
              spindles.
            </p>{" "}
            <p>
              Double and triple sets of bi-folding doors, sliding doors and
              screens.
            </p>{" "}
            <p>All types of hardwood and softwood joinery.</p>{" "}
            <p>Please read my testimonials page for commendations. </p>
          </ul>
        </div>
      </div>
    );
  }
}
export default ServicesPageContent;
