import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navigation from "./navigation.js";
import Footer from "./footer.js";
import HeroImage from "./heroimage.js";

class Testimonials extends Component {
  render() {
    return (
      <div>
        <Navigation />
        <div className="spacer"></div>
        <HeroImage content="TESTIMONIALS" />

        <div className="testimonial-container">
          <div className="testimonial">"John has done a lot of work for us in various houses over the past 18 years. Wardrobes, desks, shelving, fitting some unusual antique doors. His work is always top quality and built to last. The finishes are excellent and he is always punctual and a delight to have around the house. I would not hesitate to recommend him to anyone looking for high quality carpentry." <span className="name">- Nicola Caisley</span></div>
          <div className="testimonial">"A friend recommended John’s work. He has built wardrobes for three bedrooms, a built in bookcase, and some drawers. He is reliable and professional and finishes the jobs neatly and to a high standard." <span className="name">- Janet Kirby</span></div>
          
          <div className="testimonial">"John is brilliant. He has built us beautiful wardrobes, under-stair storage, alcove units, and wacky bookshelves - frequently from my random Pinterest photos. He’s creative, helpful and very efficient. Genuinely cannot recommend him highly enough. Thank you John!" <span className="name">- Caroline Moore</span></div>
          
          <div className="testimonial">"John has completed a number of carpentry projects for me over the years. He offers a professional service from start to finish. Excellent workmanship, tidy worker with all jobs completed to a high standard on time and on budget.<br/>I would have no hesitation in recommending John for any carpentry work." <span className="name">- Sue Robinson</span></div>
          <div className="testimonial">"John has helped us with a number of projects over the years (a fully fitted study, stud wall extension and cupboard frame, as well as bespoke fitted furniture). His work has always been excellent. The attention to detail and pride he takes in his work are second to none. We would have absolutely no hesitation in recommending his work, and will definitely be asking him to help with further projects in the future! Thanks John." <span className="name">- Justin Darbyshire</span></div>

          <div className="testimonial">"John is superb. He was recommended by a neighbour and has built 3 custom cupboards and shelving units for us. Each trade that has followed has commented on the superb quality of Johns work! We are so pleased and will be using John for all carpentry across the rest of our renovation." <span className="name">- Mira, Hatfield</span></div>
          <div className="testimonial">"John has recently laid wood flooring in two rooms of our house. He turned up on the day he scheduled, worked solidly for two days and did a perfect job. He has fiited wardrobes built shelves all of which are perfect 15 years later. His work is excellent and he works with a precision seen rarely these days. He is neat, tidy and efficient. Given the quality he produces, his charges are reasonable. I would have no hesitation recommending John to anyone." <span className="name">- Suresh Chouthi</span></div>
          <div className="testimonial">"I have used John for carpentry projects in my home for years and have recommended to many friends who have been equally impressed.  John has designed and built fitted wardrobes in bedrooms, a walk in wardrobe for the loft, alcove storage/TV unit, radiator covers and a bespoke bookcase to match some Heal’s furniture. He is very easy to work with, reliable, professional and very neat.  I have no hesitation in recommending John for any joinery project." <span className="name">- Deborah Tugulu</span></div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default Testimonials;
