import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navigation from "./navigation.js";
import Footer from "./footer.js";
import HeroImage from "./heroimage.js";
import ContactForm from "./contactForm.js";

class Contact extends Component {
  render() {
    return (
      <div>
        <Navigation />
        <div className="spacer"></div>
        <HeroImage content="CONTACT" />
        <div className="contact-header">
          Please get in-touch using the methods below with any queries you may
          have, or if you would like to recieve a quote.
        </div>
        <div className="contact-item-container">
          <div className="phone">
            <span className="phone-icon"></span>+44 7970 677646
          </div>
          <div className="email">
            <span className="email-icon"></span>johnrichardscarpentry@gmail.com
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default Contact;
