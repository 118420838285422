import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navigation from "./navigation.js";
import Footer from "./footer.js";
import HeroImage from "./heroimage.js";
import GalleryContainer from "./galleryContainer.js";
import SampleGallery from './sampleGallery.js';


const image1 = require("../samples/bedroom-furniture/bunk bed 3.jpg");
const image2 = require("../samples/bedroom-furniture/Bunk bed.jpg");
const image3 = require("../samples/bedroom-furniture/Bunk-bed-2.jpg");
const image4 = require("../samples/bedroom-furniture/Hampsted Road 1.jpg");
const image5 = require("../samples/bedroom-furniture/Hampsted Road 2.jpg");
const image6 = require("../samples/bedroom-furniture/Hampsted road 3.jpg");
const image7 = require("../samples/bedroom-furniture/Nicola Casey 2.jpg");
const image8 = require("../samples/bedroom-furniture/Nicola casey 4.1.jpg");
const image9 = require("../samples/bedroom-furniture/Nicola casey 4.jpg");
const image10 = require("../samples/bedroom-furniture/Nicola Casey 5.jpg");
const image11 = require("../samples/bedroom-furniture/Nicola casey 6.jpg");
const image12 = require("../samples/bedroom-furniture/Nicola casey 7.jpg");
const image13 = require("../samples/bedroom-furniture/North grove 2.jpg");
const image14 = require("../samples/bedroom-furniture/North grove 3.jpg");
const image15 = require("../samples/bedroom-furniture/North grove 4.jpg");
const image16 = require("../samples/bedroom-furniture/North grove 5.jpg");
const image17 = require("../samples/bedroom-furniture/North Grove.jpg");
const image18 = require("../samples/bedroom-furniture/north grove1.jpg");
const image19 = require("../samples/bedroom-furniture/Private Road 1.jpg");
const image20 = require("../samples/bedroom-furniture/Private road 2.jpg");
const image21 = require("../samples/bedroom-furniture/Private Road 3.jpg");
const image22 = require("../samples/bedroom-furniture/Private Road 5.jpg");
const image23 = require("../samples/bedroom-furniture/Private Road 6.jpg");
const image24 = require("../samples/bedroom-furniture/Private Road.jpg");

let images =
[{
        src: image1,
        thumbnail: image1,
        thumbnailWidth: 300,
        thumbnailHeight: 320,
},{
    src: image2,
    thumbnail: image2,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image3,
    thumbnail: image3,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image4,
    thumbnail: image4,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image5,
    thumbnail: image5,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image6,
    thumbnail: image6,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image7,
    thumbnail: image7,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image8,
    thumbnail: image8,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image9,
    thumbnail: image9,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image10,
    thumbnail: image10,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image11,
    thumbnail: image11,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image12,
    thumbnail: image12,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image13,
    thumbnail: image13,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image14,
    thumbnail: image14,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image15,
    thumbnail: image15,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image16,
    thumbnail: image16,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image17,
    thumbnail: image17,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image18,
    thumbnail: image18,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image19,
    thumbnail: image19,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image20,
    thumbnail: image20,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image21,
    thumbnail: image21,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image22,
    thumbnail: image22,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image23,
    thumbnail: image23,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image24,
    thumbnail: image24,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
}
]


class BedroomFurniture extends Component {
  render() {
    return (
      <div>
        <Navigation />
        <div className="spacer"></div>
        <HeroImage content="BEDROOM FUNITURE" />
        <Link to="/samples">
        <div className='backtosamples'>&larr; Back to samples</div>
        </Link>
        <div className="sampleImageContainer">
            <SampleGallery images={images}></SampleGallery>
        </div>   
        <div className="spacer"></div>
        <Footer />
      </div>
    );
  }
}
export default BedroomFurniture;
