import React from "react";
import { HashRouter, Switch, Route, Router } from "react-router-dom";
import "./App.css";
import Home from "./components/home.js";
import Services from "./components/services.js";
import Contact from "./components/contact.js";
import Testimonials from "./components/testimonials.js";
import Samples from "./components/samples.js";

import BedroomFurniture from "./components/bedroom-furniture.js";
import Kitchens from "./components/kitchens.js";
import Wardrobes from "./components/wardrobes.js";
import Storage from "./components/storage.js";
import Miscellaneous from "./components/miscellaneous.js";

const App = () => (
  <div className="app">
    <HashRouter basename="/">
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/services" component={Services} />
        <Route path="/contact" component={Contact} />
        <Route path="/testimonials" component={Testimonials} />
        <Route exact path="/samples" component={Samples} />

        <Route exact path="/samples/bedroom-furniture" component={BedroomFurniture} />
        <Route path="/samples/kitchens" component={Kitchens} />
        <Route path="/samples/wardrobes" component={Wardrobes} />
        <Route path="/samples/storage" component={Storage} />
        <Route path="/samples/miscellaneous" component={Miscellaneous} />
      </Switch>
    </HashRouter>
  </div>
);

export default App;
