import React, { Component } from "react";
import { Link } from "react-router-dom";

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    console.log("BOOM");
    if (this.state.menu === false) {
      this.setState({
        menu: true,
      });
      document.querySelector(".menuOverlay").style.transform = "none";
    } else {
      this.setState({
        menu: false,
      });
      document.querySelector(".menuOverlay").style.transform =
        "translateY(-100vh)";
    }
  }

  render() {
    return (
      <div className="nav-container">
        <Link to="/">
          <div className="title">RICHARDSCCJ</div>
        </Link>
        <div className="link-container">
          <Link to="/">
            <div className="link">HOME</div>
          </Link>
          <Link to="/services">
            <div className="link">SERVICES</div>
          </Link>
          <Link to="/samples">
            <div className="link">SAMPLES</div>
          </Link>
          <Link to="/testimonials">
            <div className="link">TESTIMONIALS</div>
          </Link>
          <Link to="/contact">
            <div className="link">CONTACT</div>
          </Link>
        </div>
        <div className="hamburger-menu" onClick={this.toggleMenu}></div>
        <div className="menuOverlay">
          <Link to="/">
            <div className="mobile-link">HOME</div>
          </Link>
          <Link to="/services">
            <div className="mobile-link">SERVICES</div>
          </Link>
          <Link to="/samples">
            <div className="mobile-link">SAMPLES</div>
          </Link>
          <Link to="/testimonials">
            <div className="mobile-link">TESTIMONIALS</div>
          </Link>
          <Link to="/contact">
            <div className="mobile-link">CONTACT</div>
          </Link>
        </div>
      </div>
    );
  }
}
export default Navigation;
