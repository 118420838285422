import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer extends Component {
  render() {
    return (
      <div>
        <Link to="/contact">
          <div className="footer-contact">CONTACT</div>
        </Link>
        <footer>
          <div className="social-container">
            <a
              className="facebook"
              href="https://www.facebook.com/Richardsccj/"
              target="_blank"
            ></a>
            <a
              className="instagram"
              href="https://www.instagram.com/johnrichardscarpentry/"
              target="_blank"
            ></a>
          </div>
        </footer>
      </div>
    );
  }
}
export default Footer;
