import React, { Component } from "react";
import Gallery from 'react-grid-gallery';




class SampleGallery extends Component {

    

    render() {

      return (
        <div>
         <Gallery images={this.props.images} enableImageSelection={false}/>
        </div>
      );
    }
  }
  export default SampleGallery;
  

