import React, { Component } from "react";
import { Link } from "react-router-dom";

class HomepageContent extends Component {
  render() {
    return (
      <div className="homepage-content">
        <div>
        With 30 years experience of producing quality Carpentry and Joinery for a variety of clients John Richards has the practical knowledge to achieve the results you require.
        </div>
        <div className="sub-copy">
        Extensive work with Architects and Interior designers on projects which have demanded high standards of finish and a keen attention to detail. John Richards takes this experience into your home through a variety of carpentry skills.
        </div>
      </div>
    );
  }
}
export default HomepageContent;
