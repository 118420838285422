import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navigation from "./navigation.js";
import Footer from "./footer.js";
import HeroImage from "./heroimage.js";
import GalleryContainer from "./galleryContainer.js";
import SampleGallery from './sampleGallery.js';


const image1 = require("../samples/wardrobes/chomley 6.jpg");
const image2 = require("../samples/wardrobes/Bailey Corner Wardrobe (3).jpg");
const image3 = require("../samples/wardrobes/Chaloner Lucy Wardrobes.JPG");
const image4 = require("../samples/wardrobes/Chaloner Wardrobes  (2).JPG");
const image5 = require("../samples/wardrobes/Chaloner Wardrobes  (5).JPG");
const image6 = require("../samples/wardrobes/Children Wardrobe.jpg");
const image7 = require("../samples/wardrobes/chomley 1.jpg");
const image8 = require("../samples/wardrobes/chomley 7.jpg");
const image9 = require("../samples/wardrobes/chomley 8.jpg");
const image10 = require("../samples/wardrobes/chomley 9.jpg");
const image11 = require("../samples/wardrobes/clapton 1.1.jpg");
const image12 = require("../samples/wardrobes/clapton 1.jpg");
const image13 = require("../samples/wardrobes/clapton 2.1.jpg");
const image14 = require("../samples/wardrobes/Clapton 2.2.jpg");
const image15 = require("../samples/wardrobes/clapton 3.jpg");
const image16 = require("../samples/wardrobes/clapton 4.jpg");
const image17 = require("../samples/wardrobes/clapton 5.jpg");
const image18 = require("../samples/wardrobes/clapton 6.jpg");
const image19 = require("../samples/wardrobes/CLAPTON 7.jpg");
const image20 = require("../samples/wardrobes/clapton 9.jpg");
const image21 = require("../samples/wardrobes/clatpon 8.jpg");
const image22 = require("../samples/wardrobes/FW 1.jpg");
const image23 = require("../samples/wardrobes/FW 2.jpg");
const image24 = require("../samples/wardrobes/FW 3.jpg");
const image25 = require("../samples/wardrobes/FW.jpg");
const image26 = require("../samples/wardrobes/g.jpg");
const image27 = require("../samples/wardrobes/great dunmow 2.jpg");
const image28 = require("../samples/wardrobes/great dunmow.jpg");
const image29 = require("../samples/wardrobes/Gt Dunmow Wardrobes (7).jpg");
const image30 = require("../samples/wardrobes/Gt Dunmow Wardrobes (10).jpg");
const image31 = require("../samples/wardrobes/Gt Dunmow Wardrobes (12).jpg");
const image32 = require("../samples/wardrobes/Gt Dunmow Wardrobes (15).jpg");
const image33= require("../samples/wardrobes/GW 2.jpg");
const image34 = require("../samples/wardrobes/GWL 2.jpg");
const image35 = require("../samples/wardrobes/GWL 4.jpg");
const image36 = require("../samples/wardrobes/hatfield 2.jpg");
const image37 = require("../samples/wardrobes/hatfield.jpg");
const image38 = require("../samples/wardrobes/Heneritta Bullard Wardrobes (2).jpg");
const image39 = require("../samples/wardrobes/Heneritta Bullard Wardrobes (7).jpg");
const image40 = require("../samples/wardrobes/Heneritta Bullard Wardrobes (12).jpg");
const image41 = require("../samples/wardrobes/Heneritta Bullard Wardrobes (13).jpg");
const image42 = require("../samples/wardrobes/Heneritta Bullard Wardrobes (14).jpg");
const image43 = require("../samples/wardrobes/Heneritta Bullard Wardrobes.jpg");
const image44 = require("../samples/wardrobes/Holly Lodge Wardrobes (8).JPG");
const image45 = require("../samples/wardrobes/Holly Lodge Wardrobes (10).JPG");
const image46 = require("../samples/wardrobes/hugh large.jpg");
const image47 = require("../samples/wardrobes/mirror 4.jpg");
const image48 = require("../samples/wardrobes/Mirrored Wardrobes (2).jpg");
const image49 = require("../samples/wardrobes/Mirrored Wardrobes (5).jpg");
const image50 = require("../samples/wardrobes/Mirrored Wardrobes (8).jpg");
const image51 = require("../samples/wardrobes/NC 1.jpg");
const image52= require("../samples/wardrobes/NC 2.jpg");
const image53 = require("../samples/wardrobes/NC 3.jpg");
const image54 = require("../samples/wardrobes/NC 4.jpg");
const image55 = require("../samples/wardrobes/Olivia Main Wardrobe (4).jpg");
const image56 = require("../samples/wardrobes/Olivia Main Wardrobe (5).jpg");
const image57 = require("../samples/wardrobes/Olivia Main Wardrobe (6).jpg");
const image58 = require("../samples/wardrobes/Olivia Main Wardrobe.jpg");
const image59 = require("../samples/wardrobes/OR 1.jpg");
const image60 = require("../samples/wardrobes/OR 2.jpg");
const image61 = require("../samples/wardrobes/P Cohen N13 (3).JPG");
const image62 = require("../samples/wardrobes/P Cohen N13 (4).JPG");
const image63 = require("../samples/wardrobes/P Cohen N13 (7).JPG");
const image64 = require("../samples/wardrobes/pond farm 3.jpg");
const image65 = require("../samples/wardrobes/pond farm 4.jpg");
const image66 = require("../samples/wardrobes/Rita Cornwell Victorian Wardrobes (4).jpg");
const image67 = require("../samples/wardrobes/Rita Cornwell Victorian Wardrobes (9).jpg");
const image68 = require("../samples/wardrobes/selbourne 2.jpg");
const image69 = require("../samples/wardrobes/SELBOURNE 3.jpg");
const image70 = require("../samples/wardrobes/selbourne.jpg");
const image71 = require("../samples/wardrobes/Spitalfields (4).JPG");
const image72 = require("../samples/wardrobes/Spitalfields (9).JPG");
const image73 = require("../samples/wardrobes/St Albans Wardrobes (2).jpg");
const image74 = require("../samples/wardrobes/St Albans Wardrobes Stairs.JPG");
const image75 = require("../samples/wardrobes/St Albans Wardrobes.jpg");
const image76 = require("../samples/wardrobes/TC 2.jpg");
const image77 = require("../samples/wardrobes/TC 4.1.jpg");
const image78 = require("../samples/wardrobes/TC 4.jpg");
const image79 = require("../samples/wardrobes/TC 5.jpg");
const image80 = require("../samples/wardrobes/TC.jpg");
const image81 = require("../samples/wardrobes/Uplands Road N8 (2).JPG");
const image82 = require("../samples/wardrobes/Uplands Road N8 (3).JPG");
const image83 = require("../samples/wardrobes/Uplands Road N8 (5).JPG");
const image84 = require("../samples/wardrobes/Uplands Road N8 (8).JPG");
const image85 = require("../samples/wardrobes/Uplands Road N8 (9).JPG");
const image86 = require("../samples/wardrobes/Wagner Wardrobes (4).jpg");
const image87 = require("../samples/wardrobes/Wardrobe doors.jpg");
const image88 = require("../samples/wardrobes/Wardrobes (5).jpg");
const image89 = require("../samples/wardrobes/Wardrobes (7).jpg");
const image90 = require("../samples/wardrobes/Wardrobes.jpg");

let images =
[{
        src: image1,
        thumbnail: image1,
        thumbnailWidth: 300,
        thumbnailHeight: 320,
},{
    src: image2,
    thumbnail: image2,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image3,
    thumbnail: image3,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image4,
    thumbnail: image4,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image5,
    thumbnail: image5,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image6,
    thumbnail: image6,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image7,
    thumbnail: image7,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image8,
    thumbnail: image8,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image9,
    thumbnail: image9,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image10,
    thumbnail: image10,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image11,
    thumbnail: image11,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image12,
    thumbnail: image12,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image13,
    thumbnail: image13,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image14,
    thumbnail: image14,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image15,
    thumbnail: image15,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image16,
    thumbnail: image16,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image17,
    thumbnail: image17,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image18,
    thumbnail: image18,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image19,
    thumbnail: image19,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image20,
    thumbnail: image20,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image21,
    thumbnail: image21,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image22,
    thumbnail: image22,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image23,
    thumbnail: image23,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image24,
    thumbnail: image24,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image25,
    thumbnail: image25,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image26,
    thumbnail: image26,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image27,
    thumbnail: image27,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image28,
    thumbnail: image28,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image29,
    thumbnail: image29,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image30,
    thumbnail: image30,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image31,
    thumbnail: image31,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image32,
    thumbnail: image32,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image33,
    thumbnail: image33,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image34,
    thumbnail: image34,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image35,
    thumbnail: image35,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image36,
    thumbnail: image36,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image37,
    thumbnail: image37,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image38,
    thumbnail: image38,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image39,
    thumbnail: image39,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image40,
    thumbnail: image40,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image41,
    thumbnail: image41,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image42,
    thumbnail: image42,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image43,
    thumbnail: image43,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image44,
    thumbnail: image44,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
    src: image45,
    thumbnail: image45,
    thumbnailWidth: 300,
    thumbnailHeight: 320,
},{
src: image46,
thumbnail: image46,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image47,
thumbnail: image47,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image48,
thumbnail: image48,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image49,
thumbnail: image49,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image50,
thumbnail: image50,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image51,
thumbnail: image51,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image52,
thumbnail: image52,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image53,
thumbnail: image53,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image64,
thumbnail: image54,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image55,
thumbnail: image55,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image56,
thumbnail: image56,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image57,
thumbnail: image57,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image58,
thumbnail: image58,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image59,
thumbnail: image59,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image60,
thumbnail: image60,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image61,
thumbnail: image61,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image62,
thumbnail: image62,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image63,
thumbnail: image63,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image64,
thumbnail: image64,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image65,
thumbnail: image65,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image66,
thumbnail: image66,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image67,
thumbnail: image67,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image68,
thumbnail: image68,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image69,
thumbnail: image69,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image70,
thumbnail: image70,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image71,
thumbnail: image71,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image72,
thumbnail: image72,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image73,
thumbnail: image73,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image74,
thumbnail: image74,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image75,
thumbnail: image75,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image76,
thumbnail: image76,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image77,
thumbnail: image77,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image78,
thumbnail: image78,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image79,
thumbnail: image79,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image80,
thumbnail: image80,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image81,
thumbnail: image81,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image82,
thumbnail: image82,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image83,
thumbnail: image83,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image84,
thumbnail: image84,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image85,
thumbnail: image85,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image86,
thumbnail: image86,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image87,
thumbnail: image87,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image88,
thumbnail: image88,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image89,
thumbnail: image89,
thumbnailWidth: 300,
thumbnailHeight: 320,
},{
src: image90,
thumbnail: image90,
thumbnailWidth: 300,
thumbnailHeight: 320,
}
]


class Wardrobes extends Component {
  render() {
    return (
      <div>
        <Navigation />
        <div className="spacer"></div>
        <HeroImage content="WARDROBES" />
        <Link to="/samples">
        <div className='backtosamples'>&larr; Back to samples</div>
        </Link>
        <div className="sampleImageContainer">
            <SampleGallery images={images}></SampleGallery>
        </div>   
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <Footer />
      </div>
    );
  }
}
export default Wardrobes;
